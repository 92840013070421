import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchCourses(ctx, queryParams) {
      console.log("courses Fetching");
      return new Promise((resolve, reject) => {
        axios
          .get('/survey/admin/surveys', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCourse(ctx, { id }) {
      console.log("single course  Fetching" );
      return new Promise((resolve, reject) => {
        axios
          .get(`/survey/admin/surveydetail/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSurvey(ctx, { id }) {
      console.log("single course  Fetching" );
      return new Promise((resolve, reject) => {
        axios
          .get(`/survey/admin/surveydetail/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    
    fetchSubjects(ctx, queryParams) {
     
      return new Promise((resolve, reject) => {
        axios
          .get(`/survey/admin/getsurveysubjects/${queryParams.id}`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },



    updateCourseInfo(ctx, { id }) {
      console.log("update course  is launching with id : "+id);
      return ;
      return new Promise((resolve, reject) => {
        axios
          .get(`/lms/admin/course/getcoursedetail/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

  addSubject(ctx, userData) {

      console.log("addSubject Create triggered");
      console.log(userData);

   
      return new Promise((resolve, reject) => {
        axios
          .post(`/survey/admin/addSubjectToSurvey/${userData.surveyid}`, userData )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    
    deleteSubject(ctx, { id }) {
       
      console.log("delete"+id);
       
      
      var data={
        info:"surveyAttendeeDelete"
      };
      
            return new Promise((resolve, reject) => {
              axios
                .put(`/survey/admin//removesurveysubject/${id}`,data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
          },
   
    addSurvey(ctx, userData) {

      console.log("course Create triggered");
      console.log(userData);

   
      return new Promise((resolve, reject) => {
        axios
          .post('/survey/admin/survey', userData )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    draftCourse(ctx, userData) {

      console.log("draftCourse Create triggered");
      console.log(userData);

   
      return new Promise((resolve, reject) => {
        axios
          .post('/lms/admin/course/draft', userData )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    publishCourse(ctx, userData) {

      console.log("publishCourse Create triggered");
      console.log(userData);

   
      return new Promise((resolve, reject) => {
        axios
          .post('/survey/admin/surveypublish', userData )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
